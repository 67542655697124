import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {environment} from '../environments/environment';
import { AppComponent } from './app.component';
import { AppResolver } from './app.resolver';
import {PermissionsGuard} from '@zonar-ui/auth';

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        canActivate: [AuthGuard],
        resolve: {
			data: AppResolver
		},
        children: [{
            path: '',
            loadChildren: () => import('./modules/udl/udl-list/udl-list.module').then(m => m.UDLListModule),
            canActivate: [AuthGuard],
        },
        {
            path: 'udls/bulk',
            loadChildren: () => import('./modules/udl/udl-bulk-assign/udl-bulk-assign.module').then(m => m.UdlBulkAssignModule),
            canActivate: [AuthGuard],
        },
        {
            path: 'udls/:id',
            loadChildren: () => import('./modules/udl/udl-detail/udl-detail.module').then(m => m.UDLDetailModule),
            canActivate: [AuthGuard],
        },
        // Enable this route once the permissions are enforced
        {
            path: 'no-driver-reasons',
            loadChildren: () => import('./modules/no-driver-reason/no-driver-reason.module').then(m => m.NoDriverReasonModule),
            canActivate: [AuthGuard, PermissionsGuard],
        },
        {
            path: 'driver-logs',
            loadChildren: () => import('./modules/driver-logs/driver-logs.module').then(m => m.DriverLogsModule),
            canActivate: [AuthGuard, PermissionsGuard]
        }
        ]
    },
    {
        path: '**',
        loadChildren: () => import('./modules/zui-page-not-found/zui-page-not-found.module').then(m => m.ZuiPageNotFoundModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
